import { Controller } from "@hotwired/stimulus"

const GOOGLE_PLACE_TYPES = [
  "tourist_attraction",
  "point_of_interest",
  "establishment",
  "premise",
  "street",
  "route",
  "street_address",
  "locality",
  "postal_code",
  "sublocality",
  "neighborhood",
  "administrative_area_level_2",
  "administrative_area_level_1",
  "country"
]

const ADDRESS_TYPE_COMPONENTS = {
  establishment: "addressEstablishmentInput",
  locality: "addressLocalityInput",
  administrative_area_level_1: "addressAdministrativeAreaLevel1Input",
  administrative_area_level_2: "addressAdministrativeAreaLevel2Input",
  country: "addressCountryInput",
  postal_code: "addressZipCodeInput"
}

export default class extends Controller {
  static targets = [
    "input", "query", "searchCategoryInput", "searchCategoryIcon", "searchCategoryDropdown", "searchCategoryItem",
    "guestsInput", "submitButton", "categoriesWrapper", "inputsWrapper", "form", "categoryInput", "mobileFormH4",
    "googlePlaceTypeInput", "googlePlaceNameInput", "googlePlaceIdInput", "googlePlaceLatInput", "googlePlaceLngInput",
    "addressEstablishmentInput", "addressLocalityInput", "addressAdministrativeAreaLevel1Input",
    "addressAdministrativeAreaLevel2Input", "addressCountryInput", "addressZipCodeInput"
  ]

  static values = {
    autocompleteCountries: Array,
    mobile: Boolean,
    venueSearchPath: String,
    mealSearchPath: String,
    activitySearchPath: String,
    queryInputPlaceholder: Object
  }

  static outlets = ["venue-search", "form--google-autocomplete-input"]

  formGoogleAutocompleteInputOutletConnected(outlet, element) {
    outlet.selectGooglePlace = this.selectGooglePlace.bind(this)
    outlet.selectDefaultSuggestion = this.selectDefaultSuggestion.bind(this)
  }

  selectDefaultSuggestion(formattedAddress) {
    this.googlePlaceTypeInputTarget.value = "locality"
    this.googlePlaceNameInputTarget.value = null
    this.googlePlaceIdInputTarget.value = null
    this.googlePlaceLatInputTarget.value = null
    this.googlePlaceLngInputTarget.value = null
    this.focusNextInput({ target: this.queryTarget })
  }

  selectGooglePlace(placeData) {
    const placeTypes = placeData.types || null
    const isEstablishment = placeTypes?.includes("establishment")
    const formattedAddress = [(isEstablishment ? placeData.name : null), placeData.formatted_address].filter(Boolean).join(", ")

    const selectedCategory = this.mobileValue
      ? this.searchCategoryInputTarget.value
      : this.searchCategoryInputTarget.options[this.searchCategoryInputTarget.options.selectedIndex].value

    Object.entries(ADDRESS_TYPE_COMPONENTS).forEach(([type, target]) => {
      this[target + "Target"].value = placeData.address_components?.find(component => component.types.includes(type))?.long_name || null
    })

    if (placeTypes && selectedCategory == "venue") {
      this.googlePlaceTypeInputTarget.value = placeTypes.find(type => GOOGLE_PLACE_TYPES.includes(type)) || null

      // We only want to set the name and place_id if the place is an establishment.
      // These values are used to try matching venues in the database.
      this.googlePlaceNameInputTarget.value = isEstablishment ? placeData.name : null
      this.googlePlaceIdInputTarget.value = isEstablishment ? placeData.place_id : null

      // We need to set the lat/lng values in all cases because we use them to set the specific location pin on the map.
      this.googlePlaceLatInputTarget.value = placeData.geometry.location.lat()
      this.googlePlaceLngInputTarget.value = placeData.geometry.location.lng()
    }

    if (this.queryTarget.value == "" || formattedAddress != null) {
      this.queryTarget.value = formattedAddress
      this.queryTarget.setAttribute("value", formattedAddress)
    }

    this.queryTarget.blur()

    this.focusNextInput({ target: this.queryTarget })
  }

  toggleCategoryDropdown() {
    this.searchCategoryDropdownTarget.classList.toggle("d-none")
    this.searchCategoryInputTarget.focus()
  }

  openCategoryDropdown() {
    this.searchCategoryDropdownTarget.classList.remove("d-none")
    this.formGoogleAutocompleteInputOutlet.closeSuggestionDropdown()
  }

  closeCategoryDropdown(event) {
    if (this.searchCategoryDropdownTarget.contains(event?.target)) return
    if (this.searchCategoryInputTarget.contains(event?.target)) return

    this.searchCategoryDropdownTarget.classList.add("d-none");
  }

  focusNextCategory() {
    const selectedSearchCategoryIndex = Array.from(this.searchCategoryDropdownTarget.children).indexOf(this.selectedSearchCategory) || 0
    const nextSearchCategoryIndex = selectedSearchCategoryIndex + 1 >= this.searchCategoryDropdownTarget.children.length ? 0 : selectedSearchCategoryIndex + 1
    const nextSearchCategory = this.searchCategoryDropdownTarget.children[nextSearchCategoryIndex]

    this.selectedSearchCategory?.classList?.remove("selected")

    nextSearchCategory.classList.add("selected")
    this.selectedSearchCategory = nextSearchCategory
  }

  focusPreviousCategory() {
    const selectedSearchCategoryIndex = Array.from(this.searchCategoryDropdownTarget.children).indexOf(this.selectedSearchCategory) || 0
    const previousSearchCategoryIndex = selectedSearchCategoryIndex - 1 < 0 ? this.searchCategoryDropdownTarget.children.length - 1 : selectedSearchCategoryIndex - 1
    const previousSearchCategory = this.searchCategoryDropdownTarget.children[previousSearchCategoryIndex]

    this.selectedSearchCategory?.classList?.remove("selected")

    previousSearchCategory.classList.add("selected")
    this.selectedSearchCategory = previousSearchCategory
  }

  selectCategory(event) {
    if (event instanceof MouseEvent || event instanceof PointerEvent) {
      this.selectedSearchCategory = this.searchCategoryItemTargets.find(target => target.contains(event.target))
    }

    const selectedTarget = this.selectedSearchCategory

    if (selectedTarget) {
      if (this.mobileValue) {
        this.categoriesWrapperTarget.classList.add("d-none")
        this.inputsWrapperTarget.classList.remove("d-none")
        this.mobileFormH4Targets.forEach(target => {
          target.dataset.value == selectedTarget.dataset.value ? target.classList.remove("d-none") : target.classList.add("d-none")
        })
      } else {
        this.searchCategoryIconTarget.className = "input-icon-left " + selectedTarget.children[0].children[0].classList.value
      }

      this.queryTarget.placeholder = this.queryInputPlaceholderValue[selectedTarget.dataset.value]
      this.searchCategoryInputTarget.value = selectedTarget.dataset.value
    }

    if (!this.mobileValue) this.closeCategoryDropdown()
    this.focusNextInput({ target: this.searchCategoryInputTarget })
  }

  focusNextInput(event) {
    if (this.hasVenueSearchOutlet && !this.mobileValue) {
      this.submitForm()
    } else if (event.target === this.searchCategoryInputTarget) {
      this.queryTarget.focus()
    } else if (event.target === this.queryTarget) {
      this.guestsInputTarget.focus()
    } else if (event.target === this.guestsInputTarget) {
      this.submitButtonTarget.focus()
    }
  }

  showCategoriesWrapper() {
    this.categoriesWrapperTarget.classList.remove("d-none")
    this.inputsWrapperTarget.classList.add("d-none")
  }

  submitForm() {
    const searchCategory = this.searchCategoryInputTarget.value

    if (searchCategory === "venue") {
      if (this.hasVenueSearchOutlet) {
        return this.pushParamsToVenueSearchOutletFormAndSubmit()
      } else {
        this.formTarget.action = this.venueSearchPathValue
        this.categoryInputTarget.value = null
      }
    } else if (searchCategory === "meal") {
      this.formTarget.action = this.mealSearchPathValue
      this.categoryInputTarget.value = "meal"
    } else if (searchCategory === "activity") {
      this.formTarget.action = this.activitySearchPathValue
      this.categoryInputTarget.value = "activity"
    }

    this.formTarget.submit()
  }

  pushParamsToVenueSearchOutletFormAndSubmit() {
    var shouldSubmit = false
    const shouldUpdateGoogleInputs = this.searchCategoryInputTarget.value == "venue"

    this.inputTargets.forEach((e) => {
      const input = Array.from(this.venueSearchOutlet.formTarget.elements).find((f) => f.name == e.name)
      const shouldUpdateInput = !e.name.includes("google_place") || (shouldUpdateGoogleInputs && e.name.includes("google_place"))

      if (input && shouldUpdateInput) {
        if (e.value != input.value) shouldSubmit = true
        input.value = e.value
      }
    })

    if (this.venueSearchOutlet.formTarget.elements.query.value) this.venueSearchOutlet.formTarget.elements.moved_map.value = null
    if (!shouldSubmit) return

    this.venueSearchOutlet.pushFormParamsToUrl()
    this.venueSearchOutlet.submitForm()
  }
}
